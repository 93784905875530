@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Agregar en index.css o en un archivo de estilos globales */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


body {
  @apply font-sans;
  font-family: 'Poppins', sans-serif; /* Make sure to include sans-serif as a fallback */
  font-size: 14px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Disable native password toggle icon */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

input[type="password"]::-webkit-clear-button,
input[type="password"]::-webkit-inner-spin-button,
input[type="password"]::-webkit-outer-spin-button,
input[type="password"]::-webkit-textfield-decoration-container {
    display: none;
}


/* Elemento que contiene el texto */
.sidebar-text {
  opacity: 0; /* El texto está oculto por defecto */
  visibility: hidden; /* El texto no es interactivo ni visible por defecto */
  height: 0; /* El alto del texto es 0 cuando la sidebar está colapsada */
  overflow: hidden; /* Asegura que no se vean cosas fuera del contenedor */
  transition: opacity 1.3s ease, height 0s 0.3s; /* Transición de opacidad */
}

/* Cuando la sidebar está expandida, el texto debe ser visible y tener un fade */
.sidebar-expanded .sidebar-text {
  opacity: 1;
  visibility: visible;
  height: auto; /* Cuando se expande, el alto se ajusta al contenido */
  transition: opacity 1.3s ease, height 0.3s ease; /* Transición para la opacidad y el alto */
}



/* Estilos para el botón en estado de carga */
.btn.loading {
  background-color: #ccc; /* Color gris para indicar que está en carga */
  cursor: not-allowed; /* Cambia el cursor para indicar que no se puede interactuar */
}

/* Spinner en el botón */
.btn.loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 3px solid #000;
  animation: spin 1s linear infinite;
  transform: translate(-50%, -50%);
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


